<template>
  <div class="c_overlay_slider" @keyup="() => {}">
    <div class="c_overlay_slider__containter">
      <div class="c_overlay_slider__img">
        <MediaImage
          v-if="slides[currentSlideIndex].sourcesOverlay"
          class="c_gallery_grid__overlay__image c_box--object-fit--contain c_overlay_slider__img"
          :alt="slides[currentSlideIndex].title"
          :sources="slides[currentSlideIndex].sourcesOverlay"
        />
      </div>
      <div class="c_overlay_slider__caption">
        <Caption
          v-if="hasCaptions && slides[currentSlideIndex].caption"
          :content="hasCaptions && slides[currentSlideIndex].caption"
        >
        </Caption>
      </div>
    </div>
    <button
      ref="prevButton"
      class="c_overlay_slider__button c_overlay_slider__button--prev"
      type="button"
      @click="goToPrevSlide()"
    >
      <IconSVG handle="arrow_left" :replacements="{ 'Arrow Left': $t('Previous Slide') }" />
    </button>
    <button
      ref="nextButton"
      class="c_overlay_slider__button c_overlay_slider__button--next"
      type="button"
      @click="goToNextSlide()"
    >
      <IconSVG handle="arrow_right" :replacements="{ 'Arrow Right': $t('Next Slide') }" />
    </button>
  </div>
</template>

<script>
import Caption from 'Components/caption/Caption.vue';
import MediaImage from 'Components/image/MediaImage.vue';

export default {
  components: {
    Caption,
    MediaImage,
    IconSVG: () => import('Components/icon_svg/IconSVG.vue'),
  },
  props: {
    hasCaptions: {
      type: Boolean,
      default: false,
    },
    slides: Array,
  },
  data() {
    return {
      currentSlideIndex: 0,
      slidesLength: this.slides.length,
    };
  },
  methods: {
    onArrowKeyPressed(e) {
      if (e.keyCode === 39) this.goToNextSlide();
      if (e.keyCode === 37) this.goToPrevSlide();
    },
    goTo(index) {
      this.currentSlideIndex = index;
    },
    goToPrevSlide() {
      if (this.currentSlideIndex === 0) {
        this.currentSlideIndex = this.slides.length - 1;
        return;
      }
      this.currentSlideIndex--;
    },
    goToNextSlide() {
      if (this.currentSlideIndex === this.slides.length - 1) {
        this.currentSlideIndex = 0;
        return;
      }
      this.currentSlideIndex++;
    },
  },
  mounted() {
    document.addEventListener('keyup', (e) => this.onArrowKeyPressed(e));
  },
};
</script>

<style lang="scss">
.c_overlay_slider {
  --slider-arrow-svg-height: #{px_to_rem(28)};
  --slider-arrow-svg-width: #{px_to_rem(17)};
  @at-root #{&}__containter {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  @at-root #{&}__img img {
    max-width: 70vw !important;
    @include mq(200, '', 'rem') {
      max-width: 60vw !important;
    }
  }
  @at-root #{&}__button {
    border: none;
    background: none;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    svg {
      width: var(--slider-arrow-svg-width);
      height: var(--slider-arrow-svg-height);
    }
    @at-root #{&}:hover {
      color: red;
    }
    @at-root #{&}--prev {
      left: 20px;
    }
    @at-root #{&}--next {
      right: 20px;
    }
  }
}
</style>
