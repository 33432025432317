<template>
  <div class="c_gallery_grid">
    <Slider
      ref="galleryGridSlider"
      class="c_gallery_grid__slider"
      :rounded="false"
      :arrows="$mq === 'sm' ? 'inside' : arrows"
      :arrow-color="arrowColor"
    >
      <template #slides>
        <div
          class="c_gallery_grid__slider__slide"
          :class="'c_gallery_grid__slider__slide--' + layoutOfSlide"
          v-for="(slide, slideIndex) in slideCount"
          :key="slideIndex"
        >
          <div
            class="c_gallery_grid__slider__slide__image"
            v-for="(image, index) in images.slice(
              slideIndex * currentPerSlideImageCount,
              slideIndex * currentPerSlideImageCount + currentPerSlideImageCount
            )"
            :key="index"
          >
            <Button
              class="c_gallery_grid__slider__slide__image__button c_box--pos--relative c_box--full"
              unstyle
              reset
              @onClick="triggerOverlay(index + slideIndex * currentPerSlideImageCount)"
            >
              <MediaImage
                class="c_image_bg--pos--absolute"
                v-if="image.sourcesMain"
                background
                :alt="image.title"
                :sources="image.sourcesMain"
              />
              <Flex center class="c_box--mask c_gallery_grid__slider__slide__image__button--hover_effect">
                <!-- <IconSVG
                  handle="fullscreen"
                  width="40"
                  height="40"
                  :replacements="{ Fullscreen: $t('Open media in popup overlay') }"
                /> -->
              </Flex>
            </Button>
          </div>
        </div>
      </template>
    </Slider>
    <!-- class="c_gallery_grid__overlay__slider c_box&#45;&#45;black" -->
    <Overlay class="c_gallery_grid__overlay" rounded ref="grid_gallery_overlay" :show-close-button="true" theme="image">
      <!-- <MediaImage
        v-if="images[gridGalleryOverlayIndex].sourcesOverlay"
        class="c_gallery_grid__overlay__image c_box--object-fit--contain"
        :alt="images[gridGalleryOverlayIndex].title"
        :sources="images[gridGalleryOverlayIndex].sourcesOverlay"
        :caption="images[gridGalleryOverlayIndex].caption"
      /> -->
      <OverlaySlider hasCaptions :slides="images" ref="galleryOverlaySlider" />
      <!--  <Slider
        class="product__overview__gallery__overlay_slider"
        captions
        video-player
        arrows="outside"
        :onOverlay="true"
        :disable-hover-effect="true"
        :main-height="$mq === 'sm' ? '350px' : '506px'"
        :slide-image-sources="images"
        :starting-slide-index="gridGalleryOverlayIndex"
        ref="galleryOverlaySlider"
      /> -->
      <!-- <Caption v-if="images && images.length" :content="images[gridGalleryOverlayIndex].caption"></Caption> -->
    </Overlay>
  </div>
</template>

<script>
import Button from 'Components/button/Button.vue';
import Flex from 'Components/flex/Flex.vue';
import MediaImage from 'Components/image/MediaImage.vue';
import OverlaySlider from 'Components/slider/OverlaySlider.vue';
import Overlay from 'Components/overlay/Overlay.vue';
import Slider from 'Components/slider/Slider.vue';

export default {
  components: {
    Button,
    Flex,
    OverlaySlider,
    MediaImage,
    Overlay,
    Slider,
  },
  data() {
    return {
      imageTotal: this.images.length,
      gridGalleryOverlayIndex: 0,
      perSlideImageCount: {
        1: 1,
        '1x1': 2,
        '4x1': 5,
        '1x4': 5,
        '1x1x1x1': 4,
        '2x1x2': 5,
        '2x2x1x2': 7,
        '1x2x2x2': 7,
        '2x1x2x2': 7,
        '2x2x2x1': 7,
        '1x1x2x2': 6,
        '2x2x1x1': 6,
        '1x1x1x1x1x1x1x1': 8,
      },
    };
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    slideLayout: {
      type: String,
      required: true,
    },
    arrows: {
      type: String,
      default: 'outside',
    },
    arrowColor: {
      type: String,
      default: 'gray_191',
    },
    rounded: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    layoutOfSlide() {
      let returnSlideLayout = this.slideLayout;
      if (this.$mq === 'sm') {
        returnSlideLayout = '1';
      } else if (this.$mq === 'md') {
        returnSlideLayout = '1x1';
      } else {
        returnSlideLayout = this.slideLayout;
      }
      this.$nextTick(() => {
        this.reload();
      });
      return returnSlideLayout;
    },
    slideCount() {
      let numberOfSlides = this.imageTotal / 8;
      if (this.layoutOfSlide in this.perSlideImageCount) {
        numberOfSlides = this.imageTotal / this.perSlideImageCount[this.layoutOfSlide];
      }
      return Math.ceil(numberOfSlides);
    },
    currentPerSlideImageCount() {
      if (this.layoutOfSlide in this.perSlideImageCount) {
        return this.perSlideImageCount[this.layoutOfSlide];
      }
      return 8;
    },
  },
  updated() {},
  methods: {
    reload() {
      if (this.$refs?.galleryGridSlider) {
        this.$refs.galleryGridSlider.reloadMain();
      }
    },
    triggerOverlay(index) {
      this.gridGalleryOverlayIndex = index;
      this.$refs.grid_gallery_overlay.show();
      this.$nextTick(function() {
        this.$refs.galleryOverlaySlider?.goTo(index);
      });
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss">
@mixin edge15_layout($edge15_image: &) {
  @at-root .app--isEdge15orDown #{$edge15_image} {
    &:nth-child(1) {
      grid-column: 1;
      grid-row: 1;
      -ms-grid-column-span: 1;
      -ms-grid-row-span: 1;
    }
    &:nth-child(2) {
      grid-column: 2;
      grid-row: 1;
      -ms-grid-column-span: 1;
      -ms-grid-row-span: 1;
    }
    &:nth-child(3) {
      grid-column: 1;
      grid-row: 2;
      -ms-grid-column-span: 1;
      -ms-grid-row-span: 1;
    }
    &:nth-child(4) {
      grid-column: 2;
      grid-row: 2;
      -ms-grid-column-span: 1;
      -ms-grid-row-span: 1;
    }
    &:nth-child(5) {
      grid-column: 3;
      grid-row: 1;
      -ms-grid-column-span: 1;
      -ms-grid-row-span: 1;
    }
    &:nth-child(6) {
      grid-column: 4;
      grid-row: 1;
      -ms-grid-column-span: 1;
      -ms-grid-row-span: 1;
    }
    &:nth-child(7) {
      grid-column: 3;
      grid-row: 2;
      -ms-grid-column-span: 1;
      -ms-grid-row-span: 1;
    }
    &:nth-child(8) {
      grid-column: 4;
      grid-row: 2;
      -ms-grid-column-span: 1;
      -ms-grid-row-span: 1;
    }
  }
}

.c_gallery_grid {
  $self: &;
  overflow: visible;
  .agile__list {
    overflow: visible;
    .agile__slides {
      padding: px_to_rem(10) 0;
    }
  }
  .agile__nav-button {
    height: auto;
  }
  @at-root #{$self}__slider {
    $slider: &;

    @at-root #{&}__slide {
      $slide: &;
      display: grid !important;
      --gallery-grid--gap: #{px_to_rem(15)};
      --gallery-grid--template-columns: repeat(4, 1fr);
      --gallery-grid--template-rows: 1fr 1fr;
      @at-root .app--isEdge15orDown & {
        --gallery-grid--template-columns: 1fr 1fr 1fr 1fr;
      }
      grid-gap: var(--gallery-grid--gap);
      grid-template-columns: var(--gallery-grid--template-columns);
      grid-template-rows: var(--gallery-grid--template-rows);
      max-width: 100%;
      height: #{px_to_rem(300)};
      padding: 0 5px;
      box-sizing: border-box;
      .c_box--full {
        width: 100%;
      }
      @at-root #{&}--1 {
        #{$slide}__image {
          grid-column: 1;
          grid-row: 1;
        }
        --gallery-grid--template-columns: 1fr;
        --gallery-grid--template-rows: 1fr;
      }
      @at-root #{&}--4x1 {
        #{$slide}__image {
          &:last-child {
            grid-column: 3/5;
            grid-row: 1/3;
          }
          @include edge15_layout(&);
        }
      }
      @at-root #{&}--1x4 {
        #{$slide}__image {
          &:first-child {
            grid-column: 1/3;
            grid-row: 1/3;
          }
          @include edge15_layout(&);
        }
      }
      @at-root #{&}--2x1x2 {
        #{$slide}__image {
          &:nth-child(3) {
            grid-column: 2/4;
            grid-row: 1/3;
          }
          @include edge15_layout(&);
        }
      }
      @at-root #{&}--1x2x2x2 {
        #{$slide}__image {
          &:nth-child(1) {
            grid-column: 1/3;
            grid-row: 1;
          }
          @include edge15_layout(&);
        }
      }
      @at-root #{&}--2x1x2x2 {
        #{$slide}__image {
          &:nth-child(3) {
            grid-column: 3/5;
            grid-row: 1;
          }
          @include edge15_layout(&);
        }
      }
      @at-root #{&}--2x2x1x2 {
        #{$slide}__image {
          &:nth-child(5) {
            grid-column: 1/3;
            grid-row: 2;
          }
          @include edge15_layout(&);
        }
      }
      @at-root #{&}--2x2x2x1 {
        #{$slide}__image {
          &:nth-child(7) {
            grid-column: 3/5;
            grid-row: 2;
          }
          @include edge15_layout(&);
        }
      }
      @at-root #{&}--1x1x2x2 {
        #{$slide}__image {
          &:nth-child(1) {
            grid-column: 1/3;
            grid-row: 1;
          }
          &:nth-child(2) {
            grid-column: 3/5;
            grid-row: 1;
          }
          @include edge15_layout(&);
        }
      }
      @at-root #{&}--2x2x1x1 {
        #{$slide}__image {
          &:nth-child(5) {
            grid-column: 1/3;
            grid-row: 2;
          }
          &:nth-child(6) {
            grid-column: 3/5;
            grid-row: 2;
          }
          @include edge15_layout(&);
        }
      }
      @at-root #{&}--1x1 {
        #{$slide}__image {
          @include edge15_layout(&);
        }
        --gallery-grid--template-rows: 1fr;
        --gallery-grid--template-columns: repeat(2, 1fr);
        @at-root .app--isEdge15orDown & {
          --gallery-grid--template-columns: 1fr 1fr;
        }
      }
      @at-root #{&}--1x1x1x1 {
        #{$slide}__image {
          @include edge15_layout(&);
        }
        --gallery-grid--template-columns: repeat(2, 1fr);
        @at-root .app--isEdge15orDown & {
          --gallery-grid--template-columns: 1fr 1fr;
        }
      }

      @at-root #{&}__image {
        @at-root #{&}__button {
          $button: &;
          border-radius: var(--slider-border-radius);
          transition: box-shadow 0.5s ease;
          &:hover,
          &:focus {
            box-shadow: px_to_rem(3) px_to_rem(3) px_to_rem(7) px_to_rem(1) rgba($color_black_raw, 0.13);
            cursor: zoom-in;
          }
          @at-root #{&}--hover_effect {
            opacity: 0;
            color: $color_white;
            background: rgba($color_black_raw, 0.3);
            transition: opacity 0.5s ease, background 0.5s ease;
            #{$button}:hover &,
            #{$button}:focus & {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  @at-root #{$self}__overlay {
    @at-root #{&}__image {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-height: 620px;
        max-width: 80vw;
        width: auto;
      }
    }
    /*@at-root #{&}__slider {
      width: px_to_rem(800);
      max-width: 100%;
      .agile__list .agile__slides {
        padding: 0;
      }
    }*/
  }
}
</style>
